.App {
  text-align: center;
  background-color: #282c34;
  background-image: url('./image/background.png');
  background-size: cover;
  display: flex;
  /* min-height: 100vh; */
}

.App-logo {
  max-width: calc(20px + 20vmin);
}

.socials {
  font-size: calc(20px + 2vmin);
}

.landingPage {
  /* width: 70%; */
  align-items: center;
  background-color: white;
  overflow: visible;
  /* min-height: 100vh; */
  /* display: block; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color:#282c34;
  padding: 1%;
  /* height: 100vmin; */
  width:60%;
  top:20px;
  bottom: 0;
  left: 0;
  right: 0;
  /* min-height: 100vh; */
  margin: auto;
  margin-top: 10vh;
  margin-bottom: 10vh;
  /* width: 70%; */
  /* height: 70%; */

}

.mailChimp input[type=email]{
  width: 50%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  background: 0%;
}

.subscribeButton{
  /* display: block; */
  text-align: center;
  padding: 12px 20px;
  /* background-color: #555555; */
  background: 0;
  /* border: 0; */
}


.mc-test{
  position: absolute; left: -5000px;
}
.response{
  display:none
}
.mailChimp button:hover{
  /* box-shadow: 0 4px 2px -2px gray; */
  background-color: grey;
  /* box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19); */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
